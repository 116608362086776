.headerBar {
    background-color: #2c2c2c;
    color: #efefef;
    
    & > span {
        border-right: 1px solid #505050;
        padding: 1em 1.25em;
        font-size: 0.9em;
        display: inline-block;
    }
}
