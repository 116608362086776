// Custom Changes to Bootstrap
$primary: #005b96;
$light: #efefef;

// General App CSS 
i{
  margin-right: 1em;
}

.service-blocks > div{
  height: 385px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0;
  overflow: hidden;

  & img{
    width: 100%;
    height: 100%;
    transition: all 0.3s;

    &:hover{
      transform: scale(1.1);
      overflow: hidden;
    }
  }
}

.icon-wrapper {
  display: inline-block;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50%;
  margin-top: -60px;

  & > span {
    background-color: #005b96;
    display: block;
    padding: 15px;
    border-radius: 50%;
  }
}


@import "~bootstrap/scss/bootstrap";
